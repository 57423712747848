//Приховування алерта після відображення
document.addEventListener('DOMContentLoaded', function() {
    // Пошук елемента з класом "alert-subscribe"
    var alertElement = document.querySelector('.alert-subscribe');
    // Перевірка наявності елемента
    if (alertElement) {
        // Затримка в 5 секунд перед приховуванням алерта
        setTimeout(function() {
            // Приховування алерта
            alertElement.style.display = 'none';
        }, 5000); // 5000 мілісекунд = 5 секунд
    }
});